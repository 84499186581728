.app-container .posts-list-item-title {
  color: $primary-color;
  border-bottom: none;
}

.post-list-item-description {
  color: $post-time-color;
}

.posts-list {
  padding: 0;
}

.posts-list-item {
  list-style: none;
  &:not(:last-child) {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
  }
  padding: 0.4em 0;
}

.posts-list-item-description {
  display: block;
  font-size: 0.8em;
}

