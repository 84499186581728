.app-header-avatar {
  max-width: 10rem;
  max-height: 10rem;
  border: none;
}

.app-header-social a {
  color: $header-social-color;
}

.app-header .site-title {
  color: $header-title-color;
}

.app-header p {
  color: $header-description-color;
}

.app-header {
  nav {
    color: blue;

    ul {

      list-style: none;
      padding: 0;
      margin: 0;

      li {

        a {
          font-size: 1.2rem;
          color: $header-title-color;

          .icon {
            opacity: 0.5;
            font-size: 0.8rem;
            position: absolute;
          }
        }
      }
    }
  }
}

.app-header {
  padding: 2.5em;
  background: $darkest-color;
  text-align: center;
}

.app-header-avatar {
  max-width: 15rem;
  max-height: 15rem;
  border-radius: 100%;
  border: 0.5rem solid $primary-color;
}

.app-container {
  padding: 2.5rem;
}

.app-container h1 {
  color: $main-title-color;
}

.app-header-social {
  font-size: 2em;
  color: $lightest-color;

  a {
    margin: 0 0.1em;
  }
}

@media (min-width: 940px) {
  .app-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 20rem;
    min-height: 100vh;
  }

  .app-container {
    max-width: 65rem;
    margin-left: 20rem;
  }
}

.app-container {

  h2 {
    font-size: 1.5rem;
    color: $heading-color;
  }

  h3 {
    color: $heading-color;
    font-size: 1.2rem;
  }

  .posts-list {
    .posts-list-item {

      a.posts-list-item-title
      {
        color: $text-color;
      }

      span.posts-list-item-description {
        @extend %main-title-sub-description;
      }
    }
  }
}
