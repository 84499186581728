* {
  box-sizing: border-box;
}

html {
  line-height: 1.6;
}

body {
  margin: 0;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: $background-color;
  color: $light-color;
}

a {
  color: $primary-color;
  //transition: color 0.35s;
  text-decoration: none;

  &:hover {
    color: $lightest-color;
  }
}

code {
  font-family: "Source Code Pro", Menlo, Monaco, "Courier New", monospace;
}

pre {
  // A larger monospaced block of text (that isn't mixed with normal text)
  // generally looks heavier than normal text with the same font size. For this
  // reason using a smaller monospaced font size makes sense in this situation.
  code {
    font-size: .8em;
  }
  overflow: auto;
}

::selection {
  background: rgba($link-color, .25);
}
