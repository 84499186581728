$background-color: #fff;
$header-background-color: #2b2b2b;
$header-social-color: #ffa736;
$header-description-color: #f2f2f2;
$header-title-color: #f2f2f2;

$main-title-color: #1c1c1c;

$post-time-color: #959595;

$darkest-color: $header-background-color;
$dark-color: $background-color;
$light-color: $post-time-color;
$lightest-color: $header-social-color;
$link-color: #a25b00;
$primary-color: #2d2d2d;

$heading-color: #303030;
$text-color: #282828;
$link-color: #2bbaff5c;

//
// Extents
//

%main-title {
  font-size: 2rem;
  color: $heading-color;
}

%main-title-sub-description {
  color: #7a7a7a;
  font-size: 0.8rem;
}

%paragraph {
  color: inherit;
  font-size: 1.0rem;
}

%quote {
  border-left: 5px solid #b3b3b3;
  padding-left: 1rem;
  margin-left: 0px;

  p {
    color: #848484;
  }
}

%code-in-text {
  padding: .125rem .3rem;
  font-size: 85%;
  color: #3a5c77;
  background-color: #cfe9f9;
  border-radius: 5px;
}

@import 'components/base';
@import 'components/app';
@import 'components/error_404';
@import 'components/icon';
@import 'components/pagination';
@import 'components/post';
@import 'components/posts_list';
@import 'components/tag';
