.tag {
  display: inline-block;
  margin-right: 0.2em;
  padding: 0 0.6em;
  font-size: 0.9em;
  border-radius: 0.2em;
  white-space: nowrap;
  background: rgba(255, 255, 255, 0.1);
  //transition: color 0.35s, background 0.35s;

  &:hover {
    //transition: color 0.25s, background 0.05s;
    background: rgba(255, 255, 255, 0.3);
  }
}
