.app-container {
  article.post {
    header.post-header {

      h1.post-title {
        @extend %main-title;
        margin-bottom: 0;
      }

      .post-meta {
        @extend %main-title-sub-description;

        margin-bottom: 2.0rem;

        .separator {
          padding: 0 0.3em;
        }
      }
    }

    .post-content {
      color: $text-color;

      hr {
        max-width: 50%;
        border-top: 0px solid black;
        border-bottom: 2px solid rgba(134, 134, 134, 0.23);
        border-left: 0px solid black;
        border-right: 0px solid black;
      }

      p {
        @extend %paragraph;

        code {
          @extend %code-in-text;
        }
      }

      ul li code {
        @extend %code-in-text;
      }

      blockquote {
        @extend %quote;
      }

      img {
        max-width: 100%;
      }

      .highlight pre {
        border-radius: 4px;
        padding: 1rem 1.5rem;
        line-height: 1.4;

        code {
          color: inherit;
        }
      }
    }
  }
}

.app-container strong a, .app-container a {
  border-bottom: 3px solid $link-color;
  text-decoration: none;
  color: inherit;
}

.post-meta {
  font-size: 0.8em;
}
